import React, { useState, useEffect } from "react";
import GridTable, { SearchComponent } from "@nadavshaar/react-grid-table";

import "./userList.css";
import carret from "../../assets/images/pointbas.png";

import axios from "axios";
import { tab } from "@testing-library/user-event/dist/tab";
import {
  getRole,
  refreshUsers$,
  role$,
  setRefreshUser,
} from "../../services/rxjs";
import { API_URL as API } from "../../services/API";

import GetColumns from "./getColumnsAdmin.js";
import { useTranslation } from "react-i18next";

const UsersList = ({ projectId, token, onRefresh, defineUserNumber, role }) => {
  const { t } = useTranslation();
  const [rowsData, setRowsData] = useState([]);
  const [chargeTable, setChargeTable] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [userRole, setUserRole] = useState(true);
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    role$.subscribe((changeRole) => setUserRole(changeRole));
    getRole();

    refreshUsers$.subscribe((refresh) => setRefresh(refresh));

    // var tab = document.getElementsByClassName("rgt-cell-header-inner");
    var head = document.getElementsByClassName("rgt-cell-header-inner");

    var dateSpan = document.createElement("span");
    var img = document.createElement("img");
    img.setAttribute("src", "assets/images/icon/trieTab.svg");
    // img.src ="assets/images/icon/trieTab.svg";
    img.classList.add("sortB");
    dateSpan.classList.add("rgt-sort-icon");
    dateSpan.classList.add("rgt-sort-icon-ascending");
    dateSpan.appendChild(img);

    // head[0].style.justifyContent = "right";

    for (var i = 1; i < head.length; i++) {
      head[i].style.padding = "0px";
      //   head[i].appendChild(dateSpan);
    }

    getUserProject(projectId);
  }, [chargeTable, onRefresh, projectId, refresh]);

  const getUserProject = async (id_project) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const setUserNomber = (usersCount) => {
      defineUserNumber(usersCount);
    };

    const response = await axios.get(
      API + `api/userproject/list?project_id=${id_project}&role=${role}`,
      config
    );
    const data = response.data.data;

    // setRowsData(data);

    let filterData = data.filter((user) => user.email !== "admin@ammco.org");

    setUserNomber(filterData?.length);
    setRowsData(filterData);
  };

  // Translation

  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  // Listen for language change and update the state
  useEffect(() => {
    const handleLanguageChange = () => {
      setLanguage(i18n.language);
    };
    i18n.on("languageChanged", handleLanguageChange);
    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, [i18n]);
  return (
    <div>
      {userRole !== "admin" && (
        <GridTable
          enableColumnsReorder={false}
          columns={GetColumns({ setRowsData })}
          key={language}
          rows={rowsData}
          isLoading={isLoading}
          texts={{
            search: `${t("gridTable.search")}`,
            totalRows: `${t("gridTable.totalRows")}`,
            rows: `${t("gridTable.rows")}`,
            selected: `${t("gridTable.selected")}`,
            rowsPerPage: `${t("gridTable.rowsPerPage")}`,
            page: "Page:",
            of: `${t("gridTable.of")}`,
            prev: `${t("gridTable.prev")}`,
            next: `${t("gridTable.next")}`,
          }}
          icons={{
            sortAscending: (
              <img className="sortB rBottom" src={carret} alt="" />
            ),
            sortDescending: <img className="sortB" src={carret} alt="" />,
          }}
        />
      )}
      {userRole === "admin" && (
        <GridTable
          enableColumnsReorder={false}
          columns={GetColumns({ setRowsData })}
          key={language}
          rows={rowsData}
          isLoading={isLoading}
          texts={{
            search: `${t("gridTable.search")}`,
            totalRows: `${t("gridTable.totalRows")}`,
            rows: `${t("gridTable.rows")}`,
            selected: `${t("gridTable.selected")}`,
            rowsPerPage: `${t("gridTable.rowsPerPage")}`,
            page: "Page:",
            of: `${t("gridTable.of")}`,
            prev: `${t("gridTable.prev")}`,
            next: `${t("gridTable.next")}`,
          }}
          icons={{
            sortAscending: (
              <img className="sortB rBottom" src={carret} alt="" />
            ),
            sortDescending: <img className="sortB" src={carret} alt="" />,
          }}
        />
      )}
    </div>
  );
};

export default UsersList;
