import axios from "axios";
import { API_URL } from "../services/API";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";

export const useFetchVIPFilteredObsertionTypeList = () => {
  const { t, i18n } = useTranslation();
  const [finalData, setfinalData] = useState([]);

  const { data, isError, isLoading, refetch, error } = useQuery(
    ["VIPFilterObsList"],
    () => {
      return axios
        .get(
          API_URL +
            `api/user/typeObservation/vip_project?id=${localStorage.getItem(
              "vid_projectId"
            )}`
        )
        .then((res) => res.data);
    }
  );
  const currentLangage = i18n.language;

  useEffect(() => {
    if (currentLangage === "en" && data) {
      setfinalData(data.data);
    } else if (currentLangage === "fr" && data) {
      const updatedData = data.data.map((item) => {
        if (item.name === "Flash Observation") {
          return {
            ...item,
            name: `${t("dbData.obsTypes.flashObservation")}`,
          };
        } else if (item.name === "Animal") {
          return {
            ...item,
            name: `${t("dbData.obsTypes.animal")}`,
          };
        } else if (item.name === "Threat") {
          return {
            ...item,
            name: `${t("dbData.obsTypes.threat")}`,
          };
        } else if (item.name === "Crawl") {
          return {
            ...item,
            name: `${t("dbData.obsTypes.crawl")}`,
          };
        } else if (item.name === "Female Encounter") {
          return {
            ...item,
            name: `${t("dbData.obsTypes.femaleEncounter")}`,
          };
        } else if (item.name === "Nest") {
          return {
            ...item,
            name: `${t("dbData.obsTypes.nest")}`,
          };
        } else if (item.name === "Other") {
          return {
            ...item,
            name: `${t("dbData.obsTypes.others")}`,
          };
        }
        return item;
      });
      setfinalData(updatedData);
    }
  }, [data, currentLangage, t]);
  console.log(data, "fildata");
  console.log(error, "error-fil");

  return {
    FilterObsList: finalData,
    isError,
    isLoading,
    refetchFilterObs: refetch,
  };
};
export const useVIPNestIdAvailabilityCheck = () => {
  const { data, error, refetch } = useQuery(
    ["VIPNestIdAvailabilityCheck"],
    () => {
      return axios
        .get(
          API_URL +
            `api/project_vip/if_nest?id=${localStorage.getItem(
              "vid_projectId"
            )}`
        )
        .then((res) => res.data);
    }
  );
  useEffect(() => {
    refetch();
  }, [refetch]);

  return {
    isNestIdAvailable: data?.data?.exist,
    isNestIdAvailableError: error,
  };
};

export const useVIPSegmentAvailabilityCheck = () => {
  const { data, error, refetch } = useQuery(
    ["VIPsegMentAVailabilityCheck"],
    () => {
      return axios
        .get(
          API_URL +
            `api/siteSegment/vip_check?id=${localStorage.getItem(
              "vid_projectId"
            )}`
        )
        .then((res) => res.data);
    }
  );
  useEffect(() => {
    refetch();
  }, [refetch]);

  return {
    isSegAvailable: data?.data[0],
    isSegAvailableError: error,
  };
};
