import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { API_URL as API } from "../services/API";
import axios from "axios";
import { useMemo, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { refetchObs } from "../actions/projectActions";
import { logout } from "../actions/userActions";

export const useFetchProjectList = () => {
  const dispatch = useDispatch();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = userInfo.data.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const {
    data,
    error,
    failureReason,
    isError,
    isLoading,
    refetch,
    isRefetching,
  } = useQuery(
    ["projectList"],
    () => {
      return axios
        .get(API + "api/user/project", config)
        .then((res) => res.data);
    },
    {
      cacheTime: 0, // Disable caching
    }
  );

  if (failureReason && failureReason?.response?.data?.code === 401) {
    dispatch(logout());
    document.location.href = "/";
  }

  return {
    projectList: data,
    error: error,
    isError,
    isLoading,
    refetchProjects: refetch,
    projectRefetchLoading: isRefetching,
  };
};

export const useFetchadminProjectList = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = userInfo.data.token;
  const dispatch = useDispatch();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data, isError, failureReason, isLoading } = useQuery(
    ["projectAdminList"],
    () => {
      return axios
        .get(API + "api/projectuserAdmin/list", config)
        .then((res) => res.data);
    }
  );
  if (failureReason && failureReason?.response?.data?.code === 401) {
    dispatch(logout());
    document.location.href = "/";
  }

  return { projectAdminList: data, isError, isLoading };
};

// Fetch obs with react query

// Validated observations
export const useFetchObservaionList = ({ id }) => {
  const { observationTypesListFromReducer } = useSelector(
    (state) => state.observationTypesList
  );
  const { dateRangesArray } = useSelector(
    (state) => state.dateRangeStoreFunction
  );

  const pId = useSelector((state) => state.projectId);
  const { localProjectId } = pId;

  const refetchObsList = useSelector((state) => state.setRefetchObsList);
  const { refetchObsValue } = refetchObsList;
  const dispatch = useDispatch();
  const [page, setPage] = useState();
  const LIMIT = 250;
  useMemo(() => {
    return setPage(id);
  }, [id]);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = userInfo.data.token;

  const defaultApiBody = "api/user/project/observation/byPage/list";
  const fetchObservations = async (page) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(
      API +
        `${
          // Number(localProjectId) !== sirenInterNationalId
          //   ? defaultApiBody
          //   : defaultApiBody
          defaultApiBody
        }?numPage=${page}&id=${localProjectId}&itemsPerPage=${250}&data=${[
          observationTypesListFromReducer,
        ]}&date=${dateRangesArray}`,
      config
    );

    dispatch(refetchObs({ refetchObsValue: false }));
    return response.json();
  };

  const {
    data,
    isSuccess,
    hasNextPage,
    fetchNextPage,
    failureReason,
    isFetchingNextPage,
    isLoading,
    isRefetching,
    error,
    refetch,
    isError,
  } = useInfiniteQuery(
    ["ValidatedobservationsList", id],
    ({ pageParam = 1 }) => fetchObservations(pageParam),
    {
      queryFn: ({ pageParam = 1 }) => fetchObservations(pageParam),
      getNextPageParam: (lastPage, allPages) => {
        const nextPage =
          lastPage.data.length === LIMIT ? allPages.length + 1 : undefined;

        return nextPage;
      },
      queryKey: id,
      refetchIntervalInBackground: true,
    }
  );

  if (failureReason && failureReason?.response?.data?.code === 401) {
    dispatch(logout());
    document.location.href = "/";
  }

  useEffect(() => {
    if (refetchObsValue[0] !== 0) {
      refetch();
    }
  }, [refetchObsValue, refetch]);
  useEffect(() => {
    if (dateRangesArray[0] !== 0) {
      refetch();
    }
  }, [dateRangesArray, refetch]);
  useEffect(() => {
    refetch();
  }, [observationTypesListFromReducer, refetch, dateRangesArray]);

  // console.log(data, "valid obs");
  return {
    data,
    isSuccess,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isRefetchingObsList: isRefetching,
    obsListLoading: isLoading,
    obsFetchError: isError,
    error: error,
    queryRefetchValidObsList: refetch,
  };
};

// mini card fetch hook

export const useFetchMiniCardObservaionList = ({ id }) => {
  const { observationTypesListFromReducer } = useSelector(
    (state) => state.observationTypesList
  );

  const { dateRangesArray } = useSelector(
    (state) => state.dateRangeStoreFunction
  );
  const pId = useSelector((state) => state.projectId);
  const { localProjectId } = pId;
  const refetchObsList = useSelector((state) => state.setRefetchObsList);
  const { refetchObsValue } = refetchObsList;
  const dispatch = useDispatch();
  const [page, setPage] = useState();
  const LIMIT = 50;

  const defaultApiBody = "api/user/project/observation/byPage/list";
  useMemo(() => {
    return setPage(id);
  }, [id]);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = userInfo.data.token;
  const fetchObservations = async (page) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(
      API +
        `${defaultApiBody}?numPage=${page}&id=${localProjectId}&itemsPerPage=${50}&data=${[
          observationTypesListFromReducer,
        ]}&date=${dateRangesArray}`,
      config
    );

    dispatch(refetchObs({ refetchObsValue: false }));
    return response.json();
  };

  const {
    data,
    isSuccess,
    hasNextPage,
    failureReason,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
    isRefetching,
    refetch,
    isError,
  } = useInfiniteQuery(
    ["miniCardObservationsList", id],
    ({ pageParam = 1 }) => fetchObservations(pageParam),
    {
      queryFn: ({ pageParam = 1 }) => fetchObservations(pageParam),
      getNextPageParam: (lastPage, allPages) => {
        const nextPage =
          lastPage.data.length === LIMIT ? allPages.length + 1 : undefined;

        return nextPage;
      },
      queryKey: id,
      refetchIntervalInBackground: true,
    }
  );

  if (failureReason && failureReason?.response?.data?.code === 401) {
    dispatch(logout());
    document.location.href = "/";
  }

  useEffect(() => {
    if (refetchObsValue[0] !== 0) {
      refetch();
    }
  }, [refetchObsValue, refetch]);
  useEffect(() => {
    if (dateRangesArray[0] !== 0) {
      refetch();
    }
  }, [dateRangesArray, refetch]);
  useEffect(() => {
    refetch();
  }, [observationTypesListFromReducer, refetch, dateRangesArray]);

  // console.log(data, "validate");
  return {
    miniCardData: data,
    miniCardIsSuccess: isSuccess,
    miniCardHasNextPage: hasNextPage,
    miniCardFetchNextPage: fetchNextPage,
    miniCardIsFetchingNextPage: isFetchingNextPage,
    miniCardIsRefetcing: isRefetching,
    miniCardObsLoading: isLoading,
    miniCardObsFetchError: isError,
    miniCardQueryRefetchObsList: refetch,
  };
};

// UnValidated observations
export const useFetchUnvalidatedObservaionList = ({ id }) => {
  const pId = useSelector((state) => state.projectId);
  const { localProjectId } = pId;
  const { dateRangesArray } = useSelector(
    (state) => state.dateRangeStoreFunction
  );
  const refetchObsList = useSelector((state) => state.setRefetchObsList);
  const { refetchObsValue } = refetchObsList;
  const dispatch = useDispatch();
  const [page, setPage] = useState();
  const LIMIT = 250;
  useMemo(() => {
    return setPage(id);
  }, [id]);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = userInfo.data.token;
  const fetchObservations = async (page) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(
      //
      API +
        `api/user/project/observationUnvalid/byPage/list?numPage=${page}&id=${localProjectId}&itemsPerPage=${250}&data=${[
          0,
        ]}&date=${dateRangesArray}`,
      config
    );
    dispatch(refetchObs({ refetchObsValue: false }));
    return response.json();
  };

  const {
    data,
    isSuccess,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
    failureReason,
    isRefetching,
    refetch,
    error,
  } = useInfiniteQuery(
    ["UnvalidatedObservationsList", id],
    ({ pageParam = 1 }) => fetchObservations(pageParam),
    {
      queryFn: ({ pageParam = 1 }) => fetchObservations(pageParam),
      getNextPageParam: (lastPage, allPages) => {
        const nextPage =
          lastPage.data.length === LIMIT ? allPages.length + 1 : undefined;

        return nextPage;
      },
      queryKey: id,
      // refetchOnMount: true,
      refetchIntervalInBackground: true,
    }
  );

  if (failureReason && failureReason?.response?.data?.code === 401) {
    dispatch(logout());
    document.location.href = "/";
  }
  useEffect(() => {
    if (refetchObsValue[0] !== 0) {
      refetch();
    }
  }, [refetchObsValue, refetch]);
  // console.log(data, "un valicated obs");
  useEffect(() => {
    if (dateRangesArray[0] !== 0) {
      refetch();
    }
  }, [dateRangesArray, refetch]);

  useEffect(() => {
    refetch();
  }, [refetch, dateRangesArray]);
  return {
    data,
    isSuccess,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isRefetching,
    obsListLoading: isLoading,
    obsFetchError: error,
    queryRefetchObsList: refetch,
  };
};
