import React, { useState } from "react";
import "./SidebarPopup.css";

import viewImg from "../../../assets/images/view.png";
import editImg from "../../../assets/images/edit.png";
import deleteImg from "../../../assets/images/delete.png";

import ConfirmationModal from "../../modals/ConfirmationModal";
import { AiOutlineInfoCircle, AiOutlineQuestionCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteObservaion,
  deleteObservationLocally,
} from "../../../actions/projectActions";
import Loading from "../../LoadingError/Loading";

import ImageCarosel from "../../modals/ImageCarosel";
import DetailModal from "../../modals/detailObs/DetailModal";
import EditObservation from "../../modals/editObs/EditObservation";

function SidebarPopup({ project }) {
  // is user admin
  const userRoleOnProject = useSelector(
    (state) => state.userRoleOnParticularProject
  );
  const { role } = userRoleOnProject;

  const handleHideModal = () => {
    setShowProjectModal(false);
  };

  //edit modal hide and show state and function
  const [showEditModal, setShowEditModal] = useState(false);

  // caroel
  const [showCarosel, setShowCarosel] = useState(false);
  const handleShowImageCarosel = () => {
    setShowCarosel(true);
    setShowProjectModal(false);
  };
  const hideImageCarosel = () => {
    setShowCarosel(false);
  };

  const handleHideEditModal = () => {
    setShowEditModal(false);
  };
  const handleOpenEditModal = () => {
    setShowEditModal(true);
    setShowProjectModal(false);
  };
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const [showProjectModal, setShowProjectModal] = useState(false);

  // delete modal state and functions
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [observaionId, setObservaionId] = useState();

  const handleHideConfirmationModal = () => {
    setShowConfirmModal(false);
  };
  const deleteObservation = useSelector((state) => state.deleteObservation);
  const { deleteLoading, deleteError } = deleteObservation;

  const handleConfirmAction = () => {
    dispatch(deleteObservaion(observaionId));
    dispatch(deleteObservationLocally([observaionId]));
    handleHideConfirmationModal();
  };

  // deleteObsFromView

  const [deleteObsFromView, setDeleteObsFromView] = useState(false);
  const handleDeleteObsFromView = () => {
    setDeleteObsFromView(!deleteObsFromView);
  };
  const closeConfirm = () => {
    setDeleteObsFromView(false);
  };
  const closeConfirm2 = () => {
    setShowConfirmModal(false);
  };
  return (
    <>
      {
        <div className="obs__popup-container ">
          <div style={{ width: "120px" }}>
            <span
              onClick={() => {
                setShowModal(!showModal);
              }}
              style={{ float: "right" }}
            >
              <img src="assets/elements/images/icon/more_point.svg" alt="" />
            </span>
            {showModal && (
              <div className="obs__popup ">
                <img
                  src="assets/elements/images/cross.png"
                  alt=""
                  style={{ height: "10px", float: "right" }}
                  onClick={() => {
                    setShowModal(!showModal);
                  }}
                />

                <div
                  href={`#`}
                  onClick={() => {
                    setShowProjectModal(!showProjectModal);
                    setShowModal(!showModal);
                  }}
                >
                  <img src={viewImg} alt="view png" className="me-2" />
                  View
                </div>
                {role ? (
                  <>
                    <div>
                      <span
                        to={``}
                        onClick={() => {
                          setShowEditModal(!showEditModal);
                          setShowModal(!showModal);
                        }}
                      >
                        <img src={editImg} alt="view png" className="me-2" />
                        Edit
                      </span>{" "}
                    </div>
                    <div>
                      {/* <a href="#"> */}
                      <span
                        onClick={() => {
                          setShowConfirmModal(!showProjectModal);
                          setObservaionId(project.id);
                        }}
                      >
                        <img src={deleteImg} alt="view png" className="me-2" />
                        Delete
                      </span>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
        </div>
      }

      {showProjectModal && (
        // <DetailObservation
        //   editData={project}
        //   handleHideModal={handleHideModal}
        //   handleOpenEditModal={handleOpenEditModal}
        //   handleShowImageCarosel={handleShowImageCarosel}
        //   handleDeleteObsFromView={handleDeleteObsFromView}
        // />

        <DetailModal
          editData={project}
          closeDetailModal={handleHideModal}
          openIsEditModal={handleOpenEditModal}
          handleShowImageCarosel={handleShowImageCarosel}
        />
      )}
      {showEditModal && (
        <EditObservation
          editData={project}
          closeIsEditModal={handleHideEditModal}
        />
      )}

      {showCarosel && (
        <ImageCarosel
          // handleShowImageCarosel={handleShowImageCarosel}
          hideImageCarosel={hideImageCarosel}
          images={project.images}
        />
      )}

      {showConfirmModal && (
        <ConfirmationModal
          cofirmSourceIcon={
            <AiOutlineQuestionCircle
              style={{
                fontSize: "3rem",
                fontWeight: "bold",
              }}
              color="red"
            />
          }
          message={"Delete Items?"}
          details={"items will be deleted. This action cannot be undone"}
          btn1Text={"Cancel"}
          btn2Text={"Delete"}
          color={"red"}
          handleHideConfirmationModal={handleHideConfirmationModal}
          handleConfirmAction={handleConfirmAction}
          closeConfirm={closeConfirm2}
        />
      )}
      {deleteObsFromView && (
        <ConfirmationModal
          cofirmSourceIcon={
            <AiOutlineQuestionCircle
              style={{
                fontSize: "3rem",
                fontWeight: "bold",
              }}
              color="red"
            />
          }
          message={"Delete Items?"}
          details={"items will be deleted. This action cannot be undone"}
          btn1Text={"Cancel"}
          btn2Text={"Delete"}
          color={"red"}
          handleHideConfirmationModal={handleDeleteObsFromView}
          closeConfirm={closeConfirm}
          handleConfirmAction={handleConfirmAction}
        />
      )}

      {deleteLoading && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",

            transform: "translate(-50%, -50%)}",
          }}
        >
          <Loading />
        </div>
      )}
    </>
  );
}

export default SidebarPopup;
