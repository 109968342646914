import React from "react";
import PhotoCell from "./TableComponents/PhotoCell";
import ObserVationType from "./TableComponents/ObservationType";
import GetId from "./TableComponents/GetId";
import Group from "./TableComponents/Group";
import Specie from "./TableComponents/Specie";
import PatrolId from "./TableComponents/PatrolId";
import { useTranslation } from "react-i18next";
import NestID from "./TableComponents/NestID";
import GetQuestionAnswer from "./TableComponents/GetQuestionAnswer";

const GetColumnsValidObs = ({
  setRowsData,
  isSegAvailable,
  obsData,
  localProjectId,
  preventModalOpen,
  isNestIdAvailable,
}) => {
  const uniqueKeys = new Set();
  obsData &&
    obsData?.forEach((item) => {
      item?.questionsAnwsers?.forEach((qa) => {
        if (
          [
            10, 11, 16, 17, 22, 23, 30, 35, 82, 83, 84, 298, 299, 301, 302, 303,
            313, 314, 315,
          ].includes(qa.question.id)
        ) {
          uniqueKeys.add(qa.question.title);
        }
      });
    });
  const { t } = useTranslation();

  return [
    {
      id: "checkbox",
      visible: true,
      pinned: true,

      // getValue: ({ value }) => value.id,
      className: "obsTableCheckBox",
      width: "100px",

      cellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange,
        disabled,
      }) => (
        <input
          type="checkbox"
          id="1"
          data={data}
          onChange={onChange}
          value={value}
          checked={value}
          disabled={disabled}
        />
      ),
    },

    {
      id: "2",
      field: `id`,
      label: "ID",
      cellRenderer: (props) => (
        <GetId {...props} preventModalOpen={preventModalOpen} />
      ),
      // pinned: true,
      width: "80px",
    },

    {
      id: "3",
      field: "TypeObservation",
      label: t("listview.table.obsType"),
      cellRenderer: ObserVationType,
      editorCellRenderer: (props) => <ObserVationType {...props} isEdit />,

      pinned: false,
      width: "200px",
      minResizeWidth: 150,
      className: "center columnWith",
    },
    ...(isNestIdAvailable
      ? [
          {
            id: "4",
            field: "nest_id",
            label: t("listview.table.nestId"),
            cellRenderer: NestID,
            pinned: false,
            width: "100px",
            minResizeWidth: 92,
            className: "center columnWith",
          },
        ]
      : []),

    {
      id: "5",
      field: `date`,
      label: "Date",
      getValue: ({ value }) => value.date.slice(0, 10),
      className: "center columnWith table__number__align",
      minResizeWidth: 120,
      maxResizeWidth: null,
      pinned: false,
      width: "150px",
    },
    {
      id: "6",
      field: "date",
      label: t("listview.table.time"),
      getValue: ({ value }) => value.date.slice(11, 19),
      className: "center columnWith table__number__align",
      minResizeWidth: 120,
      maxResizeWidth: null,
      pinned: false,
      width: "150px",
    },

    {
      id: "7",
      field: "site",
      label: t("listview.table.site"),
      className: "center columnWith",
      minResizeWidth: 110,
      maxResizeWidth: null,
      pinned: false,
      width: "150px",
    },

    ...(isSegAvailable
      ? [
          {
            id: "8",
            field: "segment",
            label: t("listview.table.segment"),
            className: "center columnWith ",
            minResizeWidth: 140,
            maxResizeWidth: null,
            pinned: false,
            width: "150px",
          },
        ]
      : []),

    {
      id: "9",
      field: "group",
      label: t("listview.table.group"),
      className: "center columnWith",
      cellRenderer: (props) => <Group {...props} />,
      minResizeWidth: 110,
      maxResizeWidth: null,
      pinned: false,
      width: "150px",
    },

    {
      id: "10",
      field: "specie",
      label: t("listview.table.species"),
      className: "center columnWith",
      cellRenderer: (props) => <Specie {...props} />,
      minResizeWidth: 110,
      maxResizeWidth: null,
      pinned: false,

      width: "150px",
    },

    {
      id: "11",
      field: "img1",
      getValue: ({ value }) => `+3 `, //${value.length + 1}
      label: "Photo",
      width: "150px",
      cellRenderer: PhotoCell,
      editorCellRenderer: (props) => <PhotoCell {...props} />,
      minResizeWidth: 110,
      maxResizeWidth: null,
      pinned: false,
    },
    {
      id: "12",
      field: "user",
      label: t("listview.table.author"),
      className: "center columnWith",
      minResizeWidth: 120,
      maxResizeWidth: null,
      pinned: false,
      width: "150px",
    },
    {
      id: "13",
      field: "patrolId",
      label: t("listview.table.patrol"),
      cellRenderer: (props) => <PatrolId {...props} />,
      className: "center columnWith",
      minResizeWidth: 120,
      maxResizeWidth: null,
      pinned: false,
      width: "150px",
    },
    {
      id: "14",
      field: "note",
      label: "Notes",
      className: "center columnWith",
      minResizeWidth: 120,
      maxResizeWidth: null,
      pinned: false,
      width: "150px",
    },

    ...(Number(localProjectId) === 39
      ? [
          {
            id: "15",
            field: "idInaturalist",
            label: "iNaturalistID",
            className: "center columnWith table__number__align",
            minResizeWidth: 120,
            maxResizeWidth: null,
            pinned: false,
            width: "140px",
          },
        ]
      : []),
    ...Array?.from(uniqueKeys)?.map((key) => ({
      id: key,
      field: key,
      label: key,
      cellRenderer: (props) => (
        <GetQuestionAnswer {...props} questionTitle={key} />
      ),
      pinned: false,
      width: "200px",
      minResizeWidth: 150,
      className: "center columnWith",
    })),
  ];
};

export default GetColumnsValidObs;
