import React, { useState, useEffect } from "react";
import moment from "moment";
import GridTable from "@nadavshaar/react-grid-table";
import "./ListViewTable.css";
import { useSelector } from "react-redux";
import carret from "../../assets/images/pointbas.png";
import CustomHeader from "./custom/TableHeader";

import CustomSuccess from "../Alerts/CustomSuccess";
import CustomError from "../Alerts/CustomError";
import TableLoader from "./custom/TableLoader";
import NoObservation from "./custom/NoObservation";

import Loading2 from "../LoadingError/Loading2";

import {
  useNestIdAvailabilityCheck,
  useSegmentAvailabilityCheck,
} from "../../queries/useFetchOptions";
import { useFetchUnvalidatedObservaionList } from "../../queries/useMapQueriesHook";

import { useTranslation } from "react-i18next";
import GetColumnsValidObs from "./getColumns";

const ListViewTable = ({
  dates,
  obsData,

  handleCallback,
  obsListLoading,
  isRefetching,
}) => {
  const pId = useSelector((state) => state.projectId);
  const { localProjectId } = pId;

  const { queryRefetchObsList } = useFetchUnvalidatedObservaionList({
    id: localProjectId,
  });
  const successMessage = useSelector((state) => state.setSuccessMessage);
  const {
    createObsSuccessNotif,
    updateObsSuccessNotif,
    deleteObsSuccessNotif,
    validateObsSuccessNotif,
    deleteObsImageNotif,

    uploadSuccessNotif,
  } = successMessage;
  const errorMessage = useSelector((state) => state.setErrorMessage);
  const {
    createObsErrorNotif,
    updateObsErrorNotif,
    deleteObsErrorNotif,
    validateObsErrorNotif,
    uploadObsEror,
  } = errorMessage;

  // table sort icons

  var head = document.getElementsByClassName("rgt-cell-header-inner");

  // for (var i = 2; i < head.length - 1; i++) {
  for (var i = 1; i < head.length - 1; i++) {
    head[i].style.padding = "0px";
  }

  // fetch data from redux

  // delete list of observations
  const deleteList = useSelector((state) => state.deleteNumerousObservation);
  const { deleteListLoading } = deleteList;
  // delete single observations
  const deleteSingle = useSelector((state) => state.deleteObservation);
  const { deleteLoading } = deleteSingle;
  // Update state
  const editeObservation = useSelector((state) => state.editeObservation);
  const { updateLoading } = editeObservation;

  const createObs = useSelector((state) => state.createObservation);
  const { obsCreateLoading } = createObs;

  // upload data to iNaturalist

  const [rowData, setRowsData] = useState(obsData);

  // Get updated obs and update  array

  useEffect(() => {
    if (obsData && dates.length !== 0) {
      const filtered = obsData.filter((item) => {
        const createdAt = moment(item.date.date);
        const startOfDateRange = new Date(dates[0]);
        startOfDateRange.setHours(1, 0, 0, 0);
        const endOfDateRange = new Date(dates[1]);
        endOfDateRange.setHours(23, 59, 59, 999);
        return createdAt.isBetween(startOfDateRange, endOfDateRange);
      });

      setRowsData(filtered);
    } else {
      setRowsData(obsData);
    }
    //
  }, [obsData, dates]);

  // get projects ids functions

  const [selectedRows, setSelectedRows] = useState();

  useEffect(() => {
    handleCallback(selectedRows);
  }, [selectedRows, handleCallback]);

  useEffect(() => {
    setRowsData(obsData);
  }, [obsData]);

  useEffect(() => {
    if (createObsSuccessNotif) {
      queryRefetchObsList();
    }
  }, [createObsSuccessNotif]);

  // Translation

  const { t, i18n } = useTranslation();

  const [language, setLanguage] = useState(i18n.language);

  // Listen for language change and update the state
  useEffect(() => {
    const handleLanguageChange = () => {
      setLanguage(i18n.language);
    };
    i18n.on("languageChanged", handleLanguageChange);
    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, [i18n]);

  const { isSegAvailable } = useSegmentAvailabilityCheck(localProjectId);
  const { isNestIdAvailable } = useNestIdAvailabilityCheck(localProjectId);

  return (
    <>
      <div className="list__tables">
        {createObsSuccessNotif && createObsSuccessNotif === true ? (
          <CustomSuccess
            message={`${t(
              "listview.notificationMessages.obsCreatedValidView"
            )}`}
          />
        ) : (
          ""
        )}
        {updateObsSuccessNotif && updateObsSuccessNotif === true ? (
          <CustomSuccess
            message={`${t("listview.notificationMessages.obsUpdated")}`}
          />
        ) : (
          ""
        )}
        {deleteObsSuccessNotif && deleteObsSuccessNotif === true ? (
          <CustomSuccess
            message={`${t("listview.notificationMessages.obsDeleted")}`}
          />
        ) : (
          ""
        )}
        {validateObsSuccessNotif && validateObsSuccessNotif === true ? (
          <CustomSuccess
            message={`${t("listview.notificationMessages.obsValidated")}`}
          />
        ) : (
          ""
        )}

        {deleteObsImageNotif && deleteObsImageNotif === true ? (
          <CustomSuccess
            message={`${t("listview.notificationMessages.imgDeleted")}`}
          />
        ) : (
          ""
        )}
        {uploadSuccessNotif && uploadSuccessNotif === true ? (
          <CustomSuccess
            message={`${t("listview.notificationMessages.inaturalistUpdate")}`}
            // message={`${uploadMessage}`}
          />
        ) : (
          ""
        )}

        {/* Error notiff */}
        {createObsErrorNotif && createObsErrorNotif === true ? (
          <CustomError
            message={`${t("listview.notificationMessages.errorOccured")}`}
          />
        ) : (
          ""
        )}
        {updateObsErrorNotif && updateObsErrorNotif === true ? (
          <CustomError
            message={`${t("listview.notificationMessages.errorOccured")}`}
          />
        ) : (
          ""
        )}
        {deleteObsErrorNotif && deleteObsErrorNotif === true ? (
          <CustomError
            message={`${t("listview.notificationMessages.errorOccured")}`}
          />
        ) : (
          ""
        )}
        {validateObsErrorNotif && validateObsErrorNotif === true ? (
          <CustomError
            message={`${t("listview.notificationMessages.errorOccured")}`}
          />
        ) : (
          ""
        )}
        {uploadObsEror && uploadObsEror === true ? (
          <CustomError
            message={`${t("listview.notificationMessages.errorOccured")}`}
          />
        ) : (
          ""
        )}

        {deleteListLoading && (
          <div>
            <Loading2 />
          </div>
        )}
        {/* {isRefetching && (
          <div>
            <Loading2 />
          </div>
        )} */}
        {obsCreateLoading && (
          <div>
            <Loading2 />
          </div>
        )}
        {updateLoading && (
          <div>
            <Loading2 />
          </div>
        )}
        {deleteLoading && (
          <div>
            <Loading2 />
          </div>
        )}
        {obsListLoading && (
          <div>
            <Loading2 />
          </div>
        )}

        <GridTable
          columns={GetColumnsValidObs({
            obsData,
            setRowsData,
            isSegAvailable,
            localProjectId,
            isNestIdAvailable,
            // preventModalOpen,
          })}
          key={`${language}-${isSegAvailable}-${localProjectId}-${isNestIdAvailable}`}
          // isLoading={loading}
          // selectAllMode={"all"}
          selectedRowsIds={selectedRows}
          onSelectedRowsChange={setSelectedRows}
          icons={{
            sortAscending: (
              <img className="sortB rBottom" src={carret} alt="sortB" />
            ),
            sortDescending: <img className="sortB" src={carret} alt="sortB" />,
          }}
          components={{
            Header: CustomHeader,
            Loader: TableLoader,
            NoResults: NoObservation,
          }}
          texts={{
            search: `${t("gridTable.search")}`,
            totalRows: `${t("gridTable.totalRows")}`,
            rows: `${t("gridTable.rows")}`,
            selected: `${t("gridTable.selected")}`,
            rowsPerPage: `${t("gridTable.rowsPerPage")}`,
            page: "Page:",
            of: `${t("gridTable.of")}`,
            prev: `${t("gridTable.prev")}`,
            next: `${t("gridTable.next")}`,
          }}
          showSearch={false}
          rows={rowData}
        />
      </div>
    </>
  );
};

export default ListViewTable;
