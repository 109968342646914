import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import "./addProject.css";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Modal } from "react-bootstrap";
import TimezoneSelect, { allTimezones } from "react-timezone-select";

import { setData } from "../../actions/projectActions";

// import { useNavigate } from "react-router-dom"

import {
  setProject,
  setCreateProject,
  setSuccessCreateProject,
  operationOnProject,
} from "../../services/rxjs";
import { API_URL as API } from "../../services/API";
import { useDispatch, useSelector } from "react-redux";

import SiteSegment from "./SiteSegment";
import Loading2 from "../LoadingError/Loading2";
import { useTranslation } from "react-i18next";

const AddProject = ({
  childrenCheck,
  user,
  project,
  country,
  updateProject,
  admin,
  creation,
  projectRefresh,
}) => {
  //keep this part
  //*

  const [radio, setRadio] = useState();
  // const [country, setCountry] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const modified = false;

  // const [changed, setChanged] = useState(false);
  const [projectCountry, setProjectCountry] = useState({});

  const [inputEnterTest, setInputEnterTest] = useState([false, false, false]);
  const [nomDuProject, setNomDuProject] = useState("");
  const [nomDuLocation, setNomDuLlocation] = useState("");
  const [nomOrganization, setNomOrganization] = useState("");
  const [errorProjectName, setErrorProjectName] = useState(false);
  const [validate, setValidate] = useState(false);

  const [errorLocation, setErrorLocation] = useState(false);
  const [errorOrganization, setErrorOrganization] = useState(false);
  const [haveEnterValue, setHaveEnterValue] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const defaultContry = {
    value: 36,
    label: "Cameroon",
  };

  const onChangeProject = (e) => {
    let copy = [...inputEnterTest];
    setNomDuProject(e.target.value);
    if (e.target.value === "") {
      setErrorProjectName(true);
      copy[0] = false;
    } else {
      setErrorProjectName(false);
      copy[0] = true;
    }

    setInputEnterTest(copy);
    checkValidate(0, copy[0]);
  };

  const onChangeLocation = (e) => {
    let copy = [...inputEnterTest];
    setNomDuLlocation(e.target.value);
    if (e.target.value === "") {
      setErrorLocation(true);
      copy[1] = false;
    } else {
      setErrorLocation(false);
      copy[1] = true;
    }

    setInputEnterTest(copy);
    checkValidate(1, copy[1]);
  };

  const onChangeOrganization = (e) => {
    let copy = [...inputEnterTest];
    setNomOrganization(e.target.value);
    if (e.target.value === "") {
      setErrorOrganization(true);
      copy[2] = false;
    } else {
      setErrorOrganization(false);
      copy[2] = true;
    }

    setInputEnterTest(copy); //nest pas tjrs modifier
    checkValidate(2, copy[2]);
  };

  const checkValidate = (position, val) => {
    let check = false;
    switch (position) {
      case 0:
        check = val && inputEnterTest[1] && inputEnterTest[2];
        break;
      case 1:
        check = val && inputEnterTest[0] && inputEnterTest[2];
        break;
      case 2:
        check = val && inputEnterTest[1] && inputEnterTest[0];
        break;
      default:
        check = false;
    }

    setValidate(check);
    // setCreateProject(check);
    setHaveEnterValue(true);
    setCreateProject(true);
  };

  const handleDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const [selectedTimezone, setSelectedTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  useEffect(() => {
    // role$.subscribe((changeRole) => setUserRole(changeRole));
    // getRole();
    operationOnProject(0);
    setCreateProject(false);
  }, []);

  const closeAdd = (e) => {
    if (validate || haveEnterValue) setShowDeleteModal(true);
    else childrenCheck(2);
  };

  const goToAccount = () => {
    setCreateProject(false);
    childrenCheck(2);
  };
  //*

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // Form inputs validation using react hook forms to handle the form and yup for the validation
  const validationSchema = Yup.object().shape({
    // email: yup.string().email().required("email adress is required"),
    // password: yup.string().required("password required"),
    projectname: Yup.string().required("This field is required"),
    location: Yup.string().required("This field is required"),
    organization: Yup.string().required("This field is required"),
    public: Yup.boolean().required("This field is required"),
  });

  // const navigate = useNavigate();

  const {
    formState: { errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (e) => {
    e.preventDefault();
    console.warn("public valeur: ", radio);
    setIsLoading(true);
    let pays = {};
    let link = "";
    let publi = radio ? 1 : 0;
    let timez = "";

    link = "api/project/create";
    // setCreated(true);
    // setChanged(false);

    if (projectCountry.value === undefined) {
      pays = defaultContry;
    } else {
      pays = projectCountry;
    }

    if (selectedTimezone.value) timez = selectedTimezone.value;
    else timez = selectedTimezone;

    let siteSegments = [];
    siteSegments = arrayList
      .filter((element) => typeof element === "object")
      .map(({ index, ...rest }) => rest);

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };

    let info = {
      name: nomDuProject,
      country_id: pays.value,
      country: pays.label,
      organization: nomOrganization,
      location: nomDuLocation,
      timeZone: timez,
      public: publi,
      timezone: timez,
      siteSegments: siteSegments,
    };

    axios
      .post(API + link, info, config)
      .then(function (response) {
        //mise a jour global

        setProject(JSON.stringify(response.data.data));
        localStorage.setItem("projectId", response.data.data.id);
        localStorage.setItem("projectName", info.name);
        dispatch(setData(response.data.data.id));

        // setModif(true);
        setCreateProject(false);
        setSuccessCreateProject();
        childrenCheck(2);

        // Loading
        setIsLoading(false);
        // displayMsg();

        // reset({}, { keepValues: true });
      })
      .catch(function (error) {
        console.log("error:");
        setIsLoading(false);
        console.log(error);
      })
      .finally(function () {
        setIsLoading(false);
      });
  };

  const choix = (c) => {
    setProjectCountry(c);
  };

  const timezne = (e) => {
    setSelectedTimezone(e);
  };

  const checkRadio = () => {
    setRadio(!radio);
  };

  const dispatch = useDispatch();

  // segment functions and states

  const [arrayList, setArrayList] = useState([0]);

  const [siteIndexFromChild, setSiteIndexFromChild] = useState(null);

  const handleGetSiteIndexFromChild = (siteIndex) => {
    setSiteIndexFromChild(siteIndex);
  };
  const handleUpdate = (ind) => {
    setArrayList((prev) => [...prev, arrayList[arrayList.length - 1] + 1]);
  };
  const { siteSegmentObject } = useSelector(
    (state) => state.siteSegentFunction
  );
  useEffect(() => {
    if (siteSegmentObject) {
      arrayList[siteSegmentObject?.index] = siteSegmentObject;
      setArrayList(arrayList);
    }
  }, [siteSegmentObject]);

  useEffect(() => {
    if (siteIndexFromChild) {
      // const newSitesArray = arrayList.filter(
      //   (item, index) => index !== siteIndexFromChild
      // );
      const seletedElement = (arrayList[siteIndexFromChild] =
        siteIndexFromChild);
      arrayList[siteIndexFromChild] = seletedElement;
      // const lastElement = newSitesArray[newSitesArray.length - 1];
      // newSitesArray[newSitesArray.length - 1] = lastElement - 1;

      setSiteIndexFromChild(null);
    }
  }, [siteIndexFromChild]);
  const { t } = useTranslation();

  return (
    <>
      {isLoading && <Loading2 />}

      <div className="admin-content flex-fill">
        <div className="container position-relative addProject__container">
          {modified && (
            <div className="row pt-3 pb-3 succedmsg mb-3">
              <div className="col-md-12 offset-md-1 position-relative ">
                <svg
                  width="32"
                  height="22"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 0C7.168 0 0 7.168 0 16C0 24.832 7.168 32 16 32C24.832 32 32 24.832 32 16C32 7.168 24.832 0 16 0ZM16 28.8C8.944 28.8 3.2 23.056 3.2 16C3.2 8.944 8.944 3.2 16 3.2C23.056 3.2 28.8 8.944 28.8 16C28.8 23.056 23.056 28.8 16 28.8ZM23.344 8.928L12.8 19.472L8.656 15.344L6.4 17.6L12.8 24L25.6 11.2L23.344 8.928Z"
                    fill="#138101"
                  />
                </svg>
                <span className="ml-5">
                  {" "}
                  {t("admin.projectDetails.projectCreateSuccessNotif")}
                </span>
              </div>
            </div>
          )}
          <form onSubmit={onSubmit} className="mx-2 ">
            <div className="row">
              {!modified ? (
                <div className="col-md-12 text-end">
                  <div className="divide">&nbsp;</div>
                  <img
                    onClick={() => {
                      closeAdd();
                    }}
                    src="assets/elements/images/cross.png"
                    alt=""
                    style={{ height: "15px", cursor: "pointer" }}
                    className="procloseico"
                  />
                </div>
              ) : (
                <div className="col-md-12 text-end">
                  <div className="divide">&nbsp;</div>
                </div>
              )}
              <div className="col-md-4 offset-md-1 newpromt">
                <span className="title-text d-block mb-3">
                  {t("admin.projectDetails.newProject")}{" "}
                </span>
                <div className="mt-1 mb-2  position-relative">
                  <label>{t("admin.projectDetails.projectName")} *</label>
                  <br />
                  <input
                    type="text"
                    name="projectname"
                    id="projectname"
                    value={nomDuProject}
                    onChange={onChangeProject}
                    className={`w-100 inpProject form-control ${
                      errorProjectName ? "is-invalid" : ""
                    }`}
                    placeholder={`${t(
                      "admin.projectDetails.projectPlaceHolder"
                    )}`}
                  />
                </div>
                {errorProjectName && <p className="error">{t("required")}</p>}
                <div className="mt-3 mb-2  position-relative">
                  <label>{t("admin.profile.locationDetails")} *</label>
                  <br />
                  <input
                    type="text"
                    name="location"
                    value={nomDuLocation}
                    onChange={onChangeLocation}
                    placeholder={`${t(
                      "admin.projectDetails.projectLocationPlaceholder"
                    )}`}
                    className={`w-100 loc form-control ${
                      errorLocation ? "is-invalid" : ""
                    }`}
                  />
                </div>
                {errorLocation && <p className="error">{t("required")}</p>}
                <div className="mt-3 mb-2  position-relative">
                  <label>{t("organization")} *</label>
                  <br />
                  <input
                    type="text"
                    name="organization"
                    value={nomOrganization}
                    onChange={onChangeOrganization}
                    placeholder={`${t(
                      "admin.projectDetails.projectOrganizationPlaceholder"
                    )}`}
                    className={`w-100 inpProject form-control ${
                      errorOrganization ? "is-invalid" : ""
                    }`}
                  />
                </div>

                {errorOrganization && <p className="error">{t("required")}</p>}
              </div>
              <div className="col-md-4 offset-md-1 newpromt">
                <div className="position-relative mt-5 pt-3">
                  <label
                    className="form-check-label pospub title-text2"
                    from="flexSwitchCheckDefault"
                  >
                    Public
                  </label>
                  <div className="form-check form-switch position-relative">
                    <input
                      className="form-check-input posradio"
                      type="checkbox"
                      id="flexSwitchCheckDefault"
                      name="public"
                      checked={radio}
                      onChange={() => {
                        checkRadio();
                      }}
                    />
                  </div>
                </div>
                <span className="title-text d-block mb-2">&nbsp;</span>
                <div className="mt-1 mb-2  position-relative">
                  <label>{t("country")} *</label>
                  <br />
                  <Select
                    options={country}
                    // {...country}
                    onChange={(choice) => choix(choice)}
                    placeholder={`${t("countryPlaceholder")}`}
                    defaultValue={defaultContry}
                  />

                  <p className="error">{errors.country?.message}</p>
                </div>

                <div className="mt-3 mb-2  position-relative">
                  <label>{t("admin.projectDetails.projectTimeZone")} *</label>
                  <br />

                  <TimezoneSelect
                    value={selectedTimezone}
                    onChange={timezne}
                    timezones={{
                      ...allTimezones,
                      "America/Lima": "Pittsburgh",
                      "Europe/Berlin": "Frankfurt",
                    }}
                  />
                </div>
              </div>
            </div>

            {/* Site and segments */}
            {arrayList &&
              arrayList.map((siteSeg, index) => (
                <SiteSegment
                  key={index}
                  siteIndex={index}
                  arrayList={arrayList}
                  handleUpdate={handleUpdate}
                  sendSiteIndexToParent={handleGetSiteIndexFromChild}
                />
              ))}

            <div className="row mt-3 addProject__btn container">
              <div className="col-md-4 offset-md-6 ">
                <div className="text-end">
                  <button
                    disabled={!validate}
                    type="submit"
                    className=" btn-block mt-4 login-btn admin-btn-dark"
                  >
                    {t("admin.profile.saveBtn")}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>

        <Modal
          show={showDeleteModal}
          onHide={handleDeleteModal}
          backdrop="static"
          centered
          className="mx-auto"
          keyboard={false}
          dialogClassName="tailledeletemodal"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <div className=" text-end p-2 ">
            <span
              className="pe-sm-2 pointer "
              onClick={() => {
                handleDeleteModal();
              }}
            >
              <img
                src="assets/elements/images/cross.png"
                alt=""
                style={{ height: "15px", cursor: "pointer" }}
              />
            </span>
          </div>

          <Modal.Body className="pe-4 ps-4">
            <div className=" d-flex justify-content-center">
              <img
                src="assets/elements/images/delete_icon2.png"
                alt=""
                width={40}
              />
            </div>
            <div className="text-center h5 mt-3">
              {t("admin.modal.leavePage")}?
            </div>
            <div className="text-center mb-3">
              {t("admin.modal.projectNotCreated")}
            </div>
          </Modal.Body>

          <div
            className="d-flex mt-3"
            style={{
              borderTop: ".5px solid #B3B3B3",
            }}
          >
            <div
              className="text-center p-2 fs-7 cancelbtn"
              style={{
                borderRight: ".5px solid #B3B3B3",
                width: "50%",
                cursor: "pointer",
              }}
              onClick={() => {
                goToAccount();
              }}
            >
              {t("admin.modal.leave")}
            </div>
            <div
              className="p-2 text-center fs-7 validbtn"
              style={{
                width: "50%",
                color: "#404040",
                cursor: "pointer",
              }}
              onClick={() => {
                setShowDeleteModal(false);
              }}
            >
              {t("admin.modal.stay")}
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default AddProject;
