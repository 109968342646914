import React, { useEffect, useState } from "react";
import ViewPatrolModal from "../../modals/patrols/ViewPatrols";
import { useFetchPatrolList } from "../../../queries/useFetchPatrols";
import { useFetchProjectList } from "../../../queries/useMapQueriesHook";
import { useSelector } from "react-redux";
import { usePatrolVariables } from "../../../lib/usePatrolVariable";

const PatrolId = ({ data }) => {
  const { projectList } = useFetchProjectList();
  const pId = useSelector((state) => state.projectId);
  const { localProjectId } = pId;

  // Use a state to control when to fetch patrol data
  const [shouldFetch, setShouldFetch] = useState(false);

  // Fetch patrol data only when shouldFetch is true
  const { patrolData, isRefetchingPatrol, queryRefetchPatrolList } =
    useFetchPatrolList({ id: localProjectId, enabled: shouldFetch });

  const [rowData, setRowsData] = useState([]);
  const [singlePatrol, setSinglePatrol] = useState(null);

  useEffect(() => {
    if (rowData) {
      rowData.map((patrol) => {
        if (patrol.id === data.patrolId) {
          return setSinglePatrol(patrol);
        }
        return null;
      });
    }
  }, [rowData, data]);

  useEffect(() => {
    const handleSetProjectPatrols = () => {
      const patrols =
        patrolData &&
        patrolData !== "undefined" &&
        patrolData.pages.flatMap((page) => {
          return page.data;
        });
      setRowsData(patrols);
    };
    handleSetProjectPatrols();
  }, [patrolData, isRefetchingPatrol]);
  const { totalPageNumber, currentPage } = usePatrolVariables((state) => ({
    totalPageNumber: state.totalPageNumber,
    currentPage: state.currentPage,
  }));
  useEffect(() => {
    if (currentPage === totalPageNumber - 1) {
      setShouldFetch(true); // Set to true when you want to fetch
      // console.log("Patrol fetch");
      queryRefetchPatrolList();
    }
  }, [
    localProjectId,
    projectList,
    queryRefetchPatrolList,
    totalPageNumber,
    currentPage,
  ]);
  // console.log({
  //   currentPage: currentPage,
  //   totalPageNumber: totalPageNumber,
  // });

  const [showPatrolDetails, setShowPatrolDetails] = useState(false);
  const closeDetailModal = () => {
    setShowPatrolDetails(false);
  };

  return (
    <div>
      <div className="">
        <div
          style={{
            marginLeft: "15px",
            textAlign: "center",
            display: "flex",
            fontWeight: "bold",
            cursor: "pointer",
            textDecoration: "underline",
            color: "#075796",
            justifyContent: "center",
          }}
          onClick={() => {
            if (singlePatrol) {
              setShowPatrolDetails(true);
            }
          }}
        >
          {patrolData ? data.patrolId : "Loading..."}
        </div>
      </div>

      {showPatrolDetails && (
        <ViewPatrolModal
          patrolData={singlePatrol}
          closeDetailModal={closeDetailModal}
        />
      )}
    </div>
  );
};

export default PatrolId;
