import React from "react";

const NestID = ({ data }) => {
  return (
    <div>
      <div
        className="obsId"
        style={{
          textDecoration: "none",
          fontWeight: "bold",
          cursor: "text",
          paddingLeft: "20px",
        }}
      >
        {data.nest_id?.toString() === "0" || data.nest_id?.toString() === "-1"
          ? ""
          : data.nest_id}
      </div>
    </div>
  );
};

export default NestID;
